import React, { useCallback, useRef } from "react";
import styled from "styled-components";

import { PRODUCTS } from "../../../constants/products";
import { PRODUCTS as PRODUCTS_FEDERAL } from "../../../constants/V2-federal/products";
import { PRODUCTS as PRODUCTS_REFLECTION_1 } from "../../../constants/V2-reflection-1/products";
import { PRODUCTS as PRODUCTS_REFLECTION_2 } from "../../../constants/V2-reflection-2/products";
import { FAQ } from "../../../constants/faq";

import HomeHeaderSection from "../../sections/HomeHeaderSection";
import ProductsSection from "../../sections/ProductsSection";
import ApplicationStepsSection from "../../sections/ApplicationStepsSection";
import ApplicationProcessSection from "../../sections/ApplicationProcessSection";
import FAQSection from "../../sections/FAQSection";
import BenefitsSection from "../../sections/BenefitsSection";
import DisclaimerSection from "../../sections/DisclaimerSection";
import EntitiesHeaderSectionFederal from "../../V2-federal/sections/EntitiesHeaderSection";
import BusinessEntitiesSectionFederal from "../../V2-federal/sections/BusinessEntitiesSection";
import EntitiesDescriptionSectionFederal from "../../V2-federal/sections/EntitiesDescriptionSection";
import EntitiesFAQSectionFederal from "../../V2-federal/sections/EntitiesFAQSection";
import EntitiesCustomerSupportSectionFederal from "../../V2-federal/sections/EntitiesCustomerSupportSection";
import EntitiesHeaderSectionReflection1 from "../../V2-reflection-1/sections/EntitiesHeaderSection";
import BusinessEntitiesSectionReflection1 from "../../V2-reflection-1/sections/BusinessEntitiesSection";
import ApplicationStepsSectionReflection1 from "../../V2-reflection-1/sections/ApplicationStepsSection";
import EntitiesDescriptionSectionReflection1 from "../../V2-reflection-1/sections/EntitiesDescriptionSection";
import EntitiesFAQSectionReflection1 from "../../V2-reflection-1/sections/EntitiesFAQSection";
import EntitiesCustomerSupportSectionReflection1 from "../../V2-reflection-1/sections/EntitiesCustomerSupportSection";
import EntitiesHeaderSectionReflection2 from "../../V2-reflection-2/sections/EntitiesHeaderSection";
import BusinessEntitiesSectionReflection2 from "../../V2-reflection-2/sections/BusinessEntitiesSection";
import ApplicationStepsSectionReflection2 from "../../V2-reflection-2/sections/ApplicationStepsSection";
import EntitiesDescriptionSectionReflection2 from "../../V2-reflection-2/sections/EntitiesDescriptionSection";
import EntitiesFAQSectionReflection2 from "../../V2-reflection-2/sections/EntitiesFAQSection";
import EntitiesCustomerSupportSectionReflection2 from "../../V2-reflection-2/sections/EntitiesCustomerSupportSection";
import StartFilingSectionLanding1 from "../../V2-landing-1/sections/StartFilingSection";
import AboutFilingSectionLanding1 from "../../V2-landing-1/sections/AboutFilingSection";
import AboutTeamSectionLanding1 from "../../V2-landing-1/sections/AboutTeamSection";
import ContactUsSectionLanding1 from "../../V2-landing-1/sections/ContactUsSection";
import StartFilingSectionLanding2 from "../../V2-landing-2/sections/StartFilingSection";
import OurGuaranteesSection from "../../V2-landing-2/sections/OurGuaranteesSection";
import ContactUsSectionLanding2 from "../../V2-landing-2/sections/ContactUsSection";
import OurServicesSection from "../../V2-landing-2/sections/OurServicesSection";
import ContactUsSectionLanding3 from "../../V2-landing-3/sections/ContactUsSection";
import OurGuaranteesSection3 from "../../V2-landing-3/sections/OurGuaranteesSection";
import OurServicesSection3 from "../../V2-landing-3/sections/OurServicesSection";
import StartFilingSectionLanding3 from "../../V2-landing-3/sections/StartFilingSection";

const StyledHomePage = styled.div``;

function HomePage() {
  const entitiesDescriptionRef = useRef(null);
  const handleGoToDescription = useCallback(
    () => entitiesDescriptionRef.current.scrollIntoView({ behavior: "smooth", block: "start" }),
    [entitiesDescriptionRef]
  );
  const contactUsRef = useRef(null);
  const handleGoToContactUs = useCallback(
    () => contactUsRef.current.scrollIntoView({ behavior: "smooth", block: "start" }),
    [contactUsRef]
  );
  if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "federaltaxid") {
    return (
      <StyledHomePage>
        <EntitiesHeaderSectionFederal onGoToDescriptionClick={handleGoToDescription} />
        <BusinessEntitiesSectionFederal products={PRODUCTS_FEDERAL} />
        <EntitiesDescriptionSectionFederal
          products={PRODUCTS_FEDERAL}
          entitiesDescriptionRef={entitiesDescriptionRef}
        />
        <EntitiesFAQSectionFederal />
        <EntitiesCustomerSupportSectionFederal />
      </StyledHomePage>
    );
  }

  if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "reflection-1") {
    return (
      <StyledHomePage>
        <EntitiesHeaderSectionReflection1 onGoToDescriptionClick={handleGoToDescription} />
        <BusinessEntitiesSectionReflection1 products={PRODUCTS_REFLECTION_1} />
        <ApplicationStepsSectionReflection1 />
        <EntitiesDescriptionSectionReflection1
          products={PRODUCTS_REFLECTION_1}
          entitiesDescriptionRef={entitiesDescriptionRef}
        />
        {process.env.NEXT_PUBLIC_BASE_PATH !== "/2" && <EntitiesFAQSectionReflection1 />}
        {process.env.NEXT_PUBLIC_BASE_PATH !== "/2" && <EntitiesCustomerSupportSectionReflection1 />}
      </StyledHomePage>
    );
  }

  if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "reflection-2") {
    return (
      <StyledHomePage>
        <EntitiesHeaderSectionReflection2 />
        <BusinessEntitiesSectionReflection2
          products={PRODUCTS_REFLECTION_2}
          onGoToDescriptionClick={handleGoToDescription}
        />
        <ApplicationStepsSectionReflection2 />
        <EntitiesDescriptionSectionReflection2
          products={PRODUCTS_REFLECTION_2}
          entitiesDescriptionRef={entitiesDescriptionRef}
        />
        {process.env.NEXT_PUBLIC_BASE_PATH !== "/2" &&
          process.env.NEXT_PUBLIC_BASE_PATH !== "/3" &&
          process.env.NEXT_PUBLIC_BASE_PATH !== "/4" &&
          process.env.NEXT_PUBLIC_BASE_PATH !== "/5" && <EntitiesFAQSectionReflection2 />}
        {process.env.NEXT_PUBLIC_BASE_PATH !== "/2" &&
          process.env.NEXT_PUBLIC_BASE_PATH !== "/3" &&
          process.env.NEXT_PUBLIC_BASE_PATH !== "/4" &&
          process.env.NEXT_PUBLIC_BASE_PATH !== "/5" && <EntitiesCustomerSupportSectionReflection2 />}
      </StyledHomePage>
    );
  }

  if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "landing-1") {
    return (
      <StyledHomePage>
        <StartFilingSectionLanding1 />
        <AboutFilingSectionLanding1 />
        <AboutTeamSectionLanding1 />
        <ContactUsSectionLanding1 />
      </StyledHomePage>
    );
  }
  if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "landing-2") {
    return (
      <StyledHomePage>
        <StartFilingSectionLanding2 />
        <OurServicesSection onGoToContactUs={handleGoToContactUs}/>
        <OurGuaranteesSection />
        <ContactUsSectionLanding2 contactUsRef={contactUsRef} />
      </StyledHomePage>
    );
  }
  if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "landing-3") {
    return (
      <StyledHomePage>
        <StartFilingSectionLanding3 />
        <OurGuaranteesSection3 />
        <OurServicesSection3 onGoToContactUs={handleGoToContactUs}/>
        <ContactUsSectionLanding3 contactUsRef={contactUsRef} />
      </StyledHomePage>
    );
  }
  return (
    <StyledHomePage>
      <HomeHeaderSection />
      <ProductsSection products={PRODUCTS} />
      <ApplicationStepsSection />
      <ApplicationProcessSection />
      <FAQSection items={FAQ} />
      <BenefitsSection />
      <DisclaimerSection />
    </StyledHomePage>
  );
}

export default HomePage;
